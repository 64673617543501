body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.crm-header{
  background-color: var(--adm-color-primary);
  color: #fff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:where(.css-dev-only-do-not-override-18iikkb).ant-table-wrapper .ant-table-thead >tr>th,:where(.css-18iikkb).ant-table-wrapper .ant-table-thead >tr>th{
  background-color: var(--adm-color-primary) !important;
  color: #fff !important;
  padding: 10px 2px !important;
  height: 20px !important;
  text-align: center;
}
:where(.css-dev-only-do-not-override-18iikkb).ant-drawer .ant-drawer-body {
  padding: 0;
}
/* .adm-list-body {
  font-size: 14px !important;
} */
.ant-tag{
  cursor: pointer;
}
body,#root,.App{
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}
/* radio禁用后css */
.adm-radio{
  margin-right: 10px;
  margin-top: 8px;
}
.adm-radio.adm-radio-checked.adm-radio-disabled >.adm-radio-icon{
  border-color: var(--adm-color-primary);
  background-color: var(--adm-color-primary);
  color: var(--adm-color-text-light-solid);
}
/* 徽标字体css */
.adm-badge-content{
  font-size: 13px !important;
  margin: 3px;
}

/* table td css样式 */
.list-table-row>td{
  padding: 10px 2px !important;
  text-align: center;
}


/* CenterPopup css样式 */
.allot-user{
  padding: 20px;
  
}
.allot-user h3{
  font-size: 16px;
}
.allot-user button{
  margin-top: 10px;
}
.antd-mobile-icon{
  cursor: pointer;
 
}

/* 
npm install react-router-dom
npm install axios
npm install --save antd-mobile
npm install --save node-sass 
npm install http-proxy-middleware
*/
