.index{
  width: 100%;
  .adm-card{
    padding: 0 5px
  }
  .crm-card{
    border: 1px solid var(--adm-color-box);
    padding: 10px 0 10px 7px;
  }
  .card-title{
    margin-top: 10px;
    padding-left: 10px;
    background-color: var(--adm-color-box);
    cursor: pointer;
  }
  .item{
    cursor: pointer;
    text-align: center;
    width: 60px;
    height: 60px;
    border: 1px solid #dedede;
    border-radius: var(--adm-radius-s);
    padding-top: 2px;
    .desc{
      margin-top: 3px;
    }
  }
  .iconfont{
    font-size: 36px;
  }
  .icon-xiaochengxu{
    color: #00B240;
  }
}