.my-nav-bar-subtitle{
  font-size: 12px;
  color: #ababab;
  padding: 5px 0;
}
.adm-list-item-content-main {
  padding: 10px 0;
}

.adm-list-item-content,.adm-space-align-baseline{
  align-items: center;
}
.calculator-list{
  position: relative;
  box-sizing: border-box;
  margin-top: 20px;
  &::before{
    content:'';
    border-right: 1px dashed #c4c4c4;
    position: absolute;
    left: 100px;
    top: 15px;
    height: 100%;
  }
  .list-item{
    display: flex;
    font-size: 17px;
    padding-bottom: 15px;
    .nper{
      width: 100px;
      text-align: center;
      position: relative;
      box-sizing: border-box;
      &::before{
        content: '';
        width: 7px;
        height: 7px;
        background-color: #888;
        border-radius: 100%;
        position: absolute;
        right: -4px;
        top: 6px;
      }
    }
    .data{
      padding-left: 20px;
      .money{
        padding: 5px;
        font-size: 15px;
        color: #b3b3b3;
        &>span{
          margin-right: 10px;
        }
      }
      .residue{
        padding-left: 5px;
        font-size: 15px;
        color: #a5a5a5;
      }
    }
  }
}